<template>
  <div class="addons-list">
    <div class="list-title">{{ $t("cart_receipt_add_ons") }}</div>
    <div class="list-grid">
      <!-- Ticket type add-ons -->
      <template v-for="(addon, index) in addonsList">
        <addon-card
          :key="index"
          :title="addon.name"
          :price="addon.price"
          :maxQuantity="addon.remainingQuantity"
          :ticket="addon"
          @on-add-item="addAddon"
          @on-change-quantity="changeQuantity"
        ></addon-card>
      </template>
      <!-- Question add-ons -->
      <template v-for="question in addonQuestions">
        <addon-card
          :key="question.questionId"
          :title="question.questionName"
          :price="question.memPrice"
          :maxQuantity="maxQuantity"
          :ticket="question"
          @on-add-item="addQuestion"
          @on-change-quantity="changeQuestionQuantity"
        ></addon-card>
      </template>
      <template v-for="question in addonComplexQuestions">
        <addon-card
          :key="question.questionId"
          :title="question.questionName"
          :price="question.memPrice"
          :maxQuantity="maxQuantity"
          :ticket="question"
          @on-add-item="addQuestion"
          @on-change-quantity="changeQuestionQuantity"
        ></addon-card>
      </template>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    "addon-card": () => import("./AddonsListItem.vue"),
  },
  data: () => ({}),
  props: {
    addonsList: {
      type: Array,
      default: () => [],
    },
    addonQuestions: {
      type: Array,
      default: () => [],
    },
    addonComplexQuestions: {
      type: Array,
      default: () => [],
    },
    maxQuantity: Number,
  },
  computed: {
    ...mapGetters(["checkoutRegion"]),
  },
  methods: {
    ...mapActions(["updateTicketsList", "updateTicketsListItem"]),
    async addAddon(addonData) {
      let { payload, callback } = addonData;
      await this.updateTicketsList([{ ...payload, quantity: 1 }]);
      callback();
      this.$emit("list-change");
    },
    async addQuestion(questionData) {
      let { payload, callback } = questionData;
      console.log(payload);
      callback();
      this.$emit("list-change");
    },
    async changeQuantity(addonData) {
      let { payload } = addonData;
      await this.updateTicketsListItem(payload);
      this.$emit("list-change");
    },
    async changeQuestionQuantity() {
      this.$emit("list-change");
    },
  },
};
</script>
<style lang="scss" scoped>
$mobile-view: 1024px;
.addons-list {
  .list-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 21px;
  }
  .list-grid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 22px 14px;
    @media screen and (max-width: $mobile-view) {
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }
}
</style>